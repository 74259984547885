.app {
  max-width: 1200px;
  background: #fcfcfc;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  margin: 25px auto;
  border-radius: 60px;
}

.background {
  background: #e7edf1;
}
