.container {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 60px;
  margin: 20px auto 20px auto;
  padding: 20px;
  width: 80%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  background: #f4f6fa;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}

.error-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 80%;
  margin: 0px auto;
}

.container-responsive {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, minmax(40px, max-content));
  background: #f4f6fa;
  border-radius: 60px;
  width: 80%;
  margin: 10px auto 10px auto;
  padding: 20px;
  border: 1px solid #e5e5e5;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
}

.error-container-responsive {
  display: grid;
  grid-template-columns: 1fr;
  width: 80%;
  margin: 0px auto;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  color: #6b6b6b;
  text-align: justify;
}

.title {
  font-weight: 900;
  text-align: center;
  color: #008bd0;
  font-size: 21px;
  margin-bottom: 10px;
}

.check {
  margin-right: 10px;
}

.aster {
  color: red;
  font-weight: bold;
}

.mainButton {
  display: block;
  cursor: pointer;
  background: #e29000;
  margin: 20px auto 20px auto;
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  text-indent: 10px;
}

.mainButton:hover {
  background: #b87a09;
  transition: 0.3s all;
  font-weight: bold;
  transform: translateY(3px);
}

.mainButton:active {
  transition: 0.3s all;
  transform: translateY(10px);
  border: 1px solid transparent;
  opacity: 0.8;
}
