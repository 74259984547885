.app-header {
  background: #008bd0;
}

.header-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 25px 50px 0px;
}

.header-container-responsive {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  row-gap: 25px;
  padding: 25px 50px;
}

.logo {
  width: 172px;
  height: 65px;
}

.logo-responsive {
  width: 172px;
  height: 65px;
  margin: 0px 100px;
}

.username-container {
  background: #008bd0;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  min-width: 200px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-content: center;
  column-gap: 10px;
}

.username {
  margin: auto;
  color: white;
  text-transform: uppercase;
  margin-right: 20px;
}

.title-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  text-transform: uppercase;
  color: white;
  padding-bottom: 40px;
}

.main-title {
  text-align: center;
  margin: auto 10px auto 10px;
}

.line {
  height: 1px;
  border-bottom: 3px solid #00bcf8;
  width: 100px;
}
