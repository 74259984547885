.app-footer {
  background-color: #008bd0;
  padding: 35px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  color: #008bd0;
  background-image: url(../../../images/circle-white.png);
  background-repeat: no-repeat;
  background-position: center top;
}

.line {
  height: 1px;
  border-bottom: 3px solid #00bcf8;
  width: 100px;
}

.vertical-space {
  height: 100px;
}
