.product-container {
  display: flex;
  column-gap: 20px;
  justify-content: center;
  margin-bottom: 40px;
}

.product-container-responsive {
  display: flex;
  row-gap: 20px;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: center;
}
