.container {
  width: 80%;
  margin: 20px auto 20px auto;
  padding: 20px;
  display: flex;
  align-items: center;
  column-gap: 40px;
}

.container-left {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(8, minmax(40px, max-content));
  background: #f4f6fa;
  border-radius: 60px;
  width: 50%;
  padding: 20px;
  border: 1px solid #e5e5e5;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.container-right {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(10, minmax(40px, max-content));
  background: #f4f6fa;
  border-radius: 60px;
  width: 50%;
  padding: 20px;
  border: 1px solid #e5e5e5;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.container-responsive {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 10px auto 10px auto;
}

.container-left-responsive {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(8, minmax(40px, max-content));
  background: #f4f6fa;
  border-radius: 60px;
  width: 80%;
  margin: 10px auto 10px auto;
  padding: 20px;
  border: 1px solid #e5e5e5;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.container-right-responsive {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(10, minmax(40px, max-content));
  background: #f4f6fa;
  border-radius: 60px;
  width: 80%;
  margin: 10px auto 10px auto;
  padding: 20px;
  border: 1px solid #e5e5e5;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.text {
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  margin: auto;
  line-height: 28px;
  color: #505050;
  text-align: center;
}

.title {
  color: #008bd0;
}
