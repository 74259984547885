.article {
  width: 260px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.image-container {
  height: 180px;
}

.image {
  width: 260px;
  height: 180px;
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
}

.title-container {
  font-style: normal;
  line-height: 70px;
  background: #d4edfc;
  text-align: center;
}

.title {
  font-weight: 800;
  font-size: 28px;
  color: #1975a4;
}

.premium-container {
  font-style: normal;
  margin: 10px 0px;
}

.imp {
  font-weight: 300;
  font-size: 10px;
  color: #666666;
  margin-left: 20px;
}

.premium {
  font-weight: 800;
  font-size: 26px;
  color: #1975a4;
  margin-left: 20px;
}

.description {
  width: 260px;
}

.mainButton {
  display: block;
  cursor: pointer;
  background: #e29000;
  margin: 20px auto 20px auto;
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  text-indent: 10px;
}

.mainButton:hover {
  background: #b87a09;
  transition: 0.3s all;
  font-weight: bold;
  transform: translateY(3px);
}

.mainButton:active {
  transition: 0.3s all;
  transform: translateY(10px);
  border: 1px solid transparent;
  opacity: 0.8;
}
