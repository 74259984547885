.main-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 20px 40px 20px 40px;
  background: #f4f6fa;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 60px 60px 0px 0px;
}

.inner-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  column-gap: 20px;
}

.inner-container-responsive {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.steps {
  display: flex;
  flex-flow: row;
  align-items: center;
  grid-column-gap: 10px;
  height: 55px;
}

.steps-responsive {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.text-item {
  color: #6b6b6b;
  padding: 5px;
  height: 30px;
  line-height: 30px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.line-item {
  width: 40px;
  height: 1px;
  border-bottom: 1px solid #d8d8d8;
  padding: 0px 10px 0px 10px;
}

.dot-item {
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
  width: 30px;
  height: 30px;
}

.dot-text {
  font-style: normal;
  font-weight: 800;
  color: #9c9d9f;
  line-height: 30px;
  text-align: center;
}

.dot-selected {
  background: #23cc7b;
  color: white;
}

.dot-selected:hover {
  background: #058320;
  transition: 0.3s all;
  font-weight: bold;
  transform: translateY(3px);
}

.dot-selected:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.tick {
  width: 30px;
  height: 30px;
}
