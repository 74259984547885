.aster {
  color: red;
  font-weight: bold;
}

.input {
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #6b6b6b;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #6b6b6b;
}

.mainButton {
  cursor: pointer;
  background: #e29000;
  margin: 20px auto 0px auto;
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  text-indent: 10px;
}

.mainButton:hover {
  background: #b87a09;
  transition: 0.3s all;
  font-weight: bold;
  transform: translateY(3px);
}

.mainButton:active {
  transition: 0.3s all;
  transform: translateY(10px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.container {
  display: grid;
}

.content {
  grid-area: 1 / 1;
}

.overlay {
  grid-area: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.back {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.head {
  height: 50%;
  background: #008bd0;
  background-image: url(../../images/login.png);
  background-repeat: no-repeat;
  background-position: center top;
}

.main {
  height: 47%;
  background: #e7edf1;
}

.foot {
  height: 3%;
  background: #008bd0;
}

.form {
  display: flex;
  flex-flow: column;
  width: 500px;
  padding: 30px;
  margin: 150px auto;
  background: #fcfcfc;
  border: 1px solid #e5e5e5;
  border-radius: 60px;
  box-sizing: border-box;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.form-responsive {
  display: flex;
  flex-flow: column;
  padding: 30px;
  margin: 150px auto;
  background: #fcfcfc;
  border: 1px solid #e5e5e5;
  border-radius: 60px;
  box-sizing: border-box;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.line {
  height: 1px;
  border-bottom: 1px solid #e5e5e6;
  width: 100%;
  margin-top: 20px;
}

.note {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: gray;
}
