.input {
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #6b6b6b;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #6b6b6b;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #008bd0;
  text-align: center;
}

.note {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-flow: column;
  max-width: 500px;
  margin: 30px auto;
}

.mainButton {
  cursor: pointer;
  background: #e29000;
  margin: 40px auto 0px auto;
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  text-indent: 10px;
}

.mainButton:hover {
  background: #b87a09;
  transition: 0.3s all;
  font-weight: bold;
  transform: translateY(3px);
}

.mainButton:active {
  transition: 0.3s all;
  transform: translateY(10px);
  border: 1px solid transparent;
  opacity: 0.8;
}
