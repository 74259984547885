.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  background: #d3f8e6;
  border-radius: 60px;
  width: 80%;
  margin: 20px auto 20px auto;
  padding: 20px;
  border: 1px solid #04fe85;
  box-shadow: 1px 1px 2px rgb(42 160 25 / 25%);
}

.inner-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  padding: 5px;
  margin-right: 15px;
}

.image-responsive {
  width: 60px;
  height: 60px;
  object-fit: cover;
  padding: 5px;
}

.title {
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 66px;
  color: #23cc7b;
  text-align: center;
}

.text {
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 28px;
  color: #505050;
  text-align: center;
}
