.main-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 20px 40px 20px 40px;
}

.inner-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  column-gap: 20px;
  width: 100%;
}

.inner-container-responsive {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.backButton {
  cursor: pointer;
  background: white;
  border-radius: 50px;
  color: #98948d;
  text-transform: uppercase;
  white-space: nowrap;
  border: 1px solid;
  border-color: #98948d;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  text-indent: 10px;
}

.backButton:hover {
  color: #008bd0;
  border-color: #008bd0;
  transition: 0.3s all;
  font-weight: bold;
  transform: translateY(3px);
}

.backButton:active {
  transition: 0.3s all;
  transform: translateY(10px);
  opacity: 0.8;
}
