.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #008bd0;
  text-align: center;
}
