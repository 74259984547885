.aster {
  color: red;
  font-weight: bold;
}

.input {
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #6b6b6b;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #6b6b6b;
}

.form {
  display: flex;
  flex-flow: column;
  max-width: 800px;
  margin: 0px auto;
}

.mainButton {
  cursor: pointer;
  background: #e29000;
  margin: 20px auto 20px auto;
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  text-indent: 10px;
}

.mainButton:hover {
  background: #b87a09;
  transition: 0.3s all;
  font-weight: bold;
  transform: translateY(3px);
}

.mainButton:active {
  transition: 0.3s all;
  transform: translateY(10px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.title-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #008bd0;
  text-align: center;
}

.radio-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 20px 0px;
}

.radio-container-responsive {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 10px;
  margin: 20px 0px;
}

.radio {
  margin-right: 10px;
}

.label-radio {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #6b6b6b;
  background: white;
  border: 2px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  width: 250px;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, minmax(40px, max-content));
  justify-items: center;
  background: #f4f6fa;
  border-radius: 60px;
  width: 80%;
  margin: 20px auto 20px auto;
  padding: 20px;
  border: 1px solid #e5e5e5;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list li:before {
  content: "✓";
  color: #008bd0;
  margin-right: 10px;
}

.text {
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 28px;
  color: #505050;
  text-align: center;
}

.blue {
  color: #008bd0;
}

.payment-method-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment-method-inner-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment-method-inner-container-responsive {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.note {
  color: gray;
}
